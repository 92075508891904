import React, { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
//import { Button } from "../../common/Button";
//import { Link } from 'react-router-dom';

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(true);
  };

  const onClose = () => {
    setVisibility(false);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      onClose();
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("tokenomics")}>
          <Span>{t("Tokenomics")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("howtobuy")}>
          <Span>{t("How to Buy")}</Span>
        </CustomNavLinkSmall>
        {/*<CustomNavLinkSmall style={{ width: "180px" }}>
          <Span>
          <Link to={{ pathname: "https://app.uniswap.org/swap?inputCurrency=NATIVE&outputCurrency=0x116388a68C980ACE1158ff5cBADfF2CAC5f7636a&chain=polygon" }} target="_blank">
              <Button>{t("Buy now")}</Button>
            </Link>
          </Span>
        </CustomNavLinkSmall>*/}
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between" align="middle">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer 
          closable={false} 
          visible={visible} 
          onClose={onClose}
          placement="right"
          width="250px"
          bodyStyle={{ background: "#000120", padding: "0" }}
        >
          <Col style={{ marginBottom: "2.5rem", padding: "20px" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);