import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: rgba(0, 0, 30, 0.8);
  padding: 2.5rem 0;
  border-top: 1px solid rgba(0, 255, 255, 0.3);
`;

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: uppercase;
  color: #00ffff;
  margin-bottom: 1rem;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  color: rgba(0, 255, 255, 0.7);

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  }
`;

export const Extra = styled("section")`
  background: rgba(0, 0, 30, 0.6);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
`;

export const Para = styled("div")`
  color: rgba(0, 255, 255, 0.7);
  font-size: 14px;
  width: 70%;
`;

export const Large = styled(Link)<any>`
  font-size: 16px;
  color: #00ffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  max-width: max-content;

  &:hover {
    color: #ffffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  }
`;

export const Chat = styled("p")`
  color: #00ffff;
  max-width: fit-content;
  border-bottom: 1px solid #00ffff;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  }
`;

export const Empty = styled("div")`
  position: relative;
  height: 53px;
`;

export const FooterContainer = styled("div")`
  max-width: 510px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  transition: all 0.1s ease-in-out;

  a {
    &:hover,
    &:active,
    &:focus {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 769px) {
    width: auto;

    a:not(:last-child) {
      display: none;
    }
  }

  div {
    cursor: pointer;
    margin-right: 15px;
    width: 25px;
    height: 25px;

    &:hover {
      fill: #00ffff;
    }
  }
`;

export const Language = styled("h4")`
  font-size: 22px;
  text-transform: uppercase;
  color: #00ffff;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const Label = styled("label")`
  font-size: 22px;
  text-transform: uppercase;
  color: #00ffff;
  display: block;
  margin-bottom: 2rem;
  font-family: "Motiva Sans Bold", serif;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    margin-bottom: 1rem;
  }
`;

export const LanguageSwitch = styled("div")<any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;