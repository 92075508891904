import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  background: rgba(0, 0, 30, 0.7);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 255, 255, 0.3);

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
  color: #00ffff;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-shadow: 0 0 10px #00ffff;
  }
`;

export const CustomNavLink = styled(NavLink)`
  width: 203px;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
  background: linear-gradient(90deg, #00ffff, #0080ff);
  color: #000;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
  }
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: #00ffff;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #00ffff;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #00ffff;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    text-shadow: 0 0 10px #00ffff;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #00ffff;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
  color: #00ffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #ffffff;
    text-shadow: 0 0 10px #00ffff;
  }
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: #00ffff;
  transition: all 0.2s ease-in;
  margin: 0.5rem 2rem;
  text-align: left;

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }

  &:hover {
    color: #ffffff;
  }
`;